<template>
  <ValidationObserver ref="showForm" v-slot="{ handleSubmit }">
    <form method="post" @submit.prevent="handleSubmit()">
      <div class="grid grid-cols-1 row-gap-6">
        <div>
          <label class="register-label">Name of your show</label>
          <ValidationProvider rules="required" name="Name" vid="name" v-slot="{ errors }">
            <TextInput v-model="showForm.name" :errors="errors"/>
          </ValidationProvider>
        </div>

        <template v-if="showTypes.length">
          <div>
            <label class="register-label">Show Type <span class="font-normal">(select all that apply)</span></label>
            <ValidationProvider rules="required" name="Show Type" vid="show_type" class="flex flex-wrap mt-6">
              <label v-for="(type, index) in showTypes" :key="index" class="cursor-pointer select-none flex items-center text-sm mr-6 mb-4">
                <input v-model="showForm.types" :value="type.id" type="checkbox" class="form-checkbox h-5.5 w-5.5 text-pink-500">
                <span class="ml-2">{{ type.name }}</span>
              </label>
            </ValidationProvider>
          </div>

          <div v-if="showForm.types.includes(1)">
            <label class="register-label">Pro Stats</label>
            <p class="mb-2 font-roboto text-sm">To set your Pro Stats section and show verified stats of your Shows to Pro Users on your profile, search and select your podcast:</p>
            <RephonicSearch @selected="setRephonicId" />
          </div>

          <div v-for="(typeId, index) in showForm.types" :key="index">
            <label class="register-label">{{ getTypeById(typeId).name }}{{ typeId === 1 ? ' (RSS)' : '' }} URL </label>
            <ValidationProvider
              :rules="[1, 2].includes(typeId) ? 'required|url|max:255' : 'url|max:255'"
              name="Url"
              :vid="`type_url_${typeId}`"
              v-slot="{ errors }"
            >
              <div>
                <input
                  :name="`type_url_${typeId}`"
                  :id="`type_url_${typeId}`"
                  v-model="showForm.type_urls[typeId]"
                  type="url"
                  class="guestio-form-input"
                />
              </div>
              <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
        </template>

        <div>
          <label class="register-label">Description</label>
          <ValidationProvider name="Description" vid="description" v-slot="{ errors }">
            <textarea v-model="showForm.description"
                      rows="6"
                      class="guestio-form-textarea resize-none"
                      :class="{'border-red-500': errors.length}"/>
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <div>
          <label class="register-label">Tags</label>
          <TagSelect @selected="onTagSelected"/>
        </div>

        <div>
          <label class="register-label">Categories</label>
          <ValidationProvider rules="required" name="Categories" vid="categories" v-slot="{ errors }">
            <CategorySelect @selected="onCategorySelected"/>
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div>
          <label class="register-label">Show Image</label>
          <ValidationProvider name="Image" vid="imageUrl" v-slot="{ errors }" class="flex">
            <div class="w-full" >
            <MediaUploadSingle
              entity="new-show"
              :imageUrl="showForm.imageUrl"
              @uploaded="uploadedImage"
              @deleted="deletedImage"
            />
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </div>
          </ValidationProvider>
        </div>

        <div>
          <label class="register-label">Cover Image</label>
          <ValidationProvider name="Image" vid="coverImageUrl" v-slot="{ errors }" class="flex">
            <MediaUploadSingle
              entity="new-show"
              :imageUrl="showForm.coverImageUrl"
              @uploaded="uploadedCoverImage"
              @deleted="deletedCoverImage"
            />
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <!-- Links -->
        <ShowLinks :links="showForm.links"/>

        <!-- Links -->
        <ShowStats :show-stats="showForm.platform_stats"/>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import { mapState } from "vuex";
  import TextInput from "@/components/shared/TextInput"
  import ShowLinks from "@/components/register/ShowLinks"
  import ShowStats from "@/components/register/ShowStats"
  import MediaUploadSingle from '@/components/shared/MediaUploadSingle';
  import CategorySelect from '@/components/shared/CategorySelect';
  import TagSelect from '@/components/shared/TagSelect';
  import RephonicSearch from "@/components/shared/RephonicSearch";

  export default {
    name: "ShowForm",
    components: { TextInput, ShowLinks, ShowStats, MediaUploadSingle, CategorySelect, TagSelect, RephonicSearch },
    data() {
      return {
        loading: false,
        showTypes: [],
      }
    },

    computed: {
      ...mapState('register',{
        showForm: state => state.showForm,
        tags: state => state.tags,
      }),
    },

    methods: {
      onCategorySelected(categories) {
        if (categories) {
          let categoriesIds = categories.map(c => c.id);
          this.showForm.categories = categoriesIds
        }
      },

      onTagSelected(tags) {
        if (tags) {
          this.guestForm.tags = tags
        }
      },

      fetchShowTypes() {
        this.$store.dispatch('shows/fetchShowTypes').then(({ data }) => {
          this.showTypes = [...data.data]
        });
      },

      uploadedImage(url) {
        this.$store.commit('register/setImageUrl', { imageUrl: url })
      },

      deletedImage() {
        this.$store.commit('register/setImageUrl', { imageUrl: null })
      },

      uploadedCoverImage(url) {
        this.$store.commit('register/setCoverImageUrl', { coverImageUrl: url })
      },

      deletedCoverImage() {
        this.$store.commit('register/setCoverImageUrl', { coverImageUrl: null })
      },

      getTypeById(id) {
        if (! this.showTypes.length) {
          return
        }

        return this.showTypes.find(type => type.id === id)
      },

      setRephonicId(selected) {
        this.$store.commit('register/setRephonicId', selected.rephonicId);
      }
    },

    mounted() {
      window.Event.$on('showFormErrors', errors => {
        this.$refs.showForm.setErrors(errors)
      })

      this.fetchShowTypes()
    },
  }
</script>
