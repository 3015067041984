<template>
  <ValidationObserver ref="guestForm" v-slot="{ passes }">
    <form method="post" @submit.prevent="passes(submitForm)">
      <div class="grid grid-cols-1 row-gap-6">
        <!--First name-->
        <div>
          <label class="register-label">Your name</label>
          <ValidationProvider rules="required" name="Name" vid="name" v-slot="{ errors }">
            <TextInput v-model="guestForm.name" :errors="errors" />
          </ValidationProvider>
        </div>

        <!--Title-->
        <div>
          <label class="register-label">Short description</label>
          <ValidationProvider rules="required|max:25" name="Description" vid="title" v-slot="{ errors }">
            <TextInput v-model="guestForm.title" :errors="errors" placeholder="e.g. Nobel Prize winner" />
          </ValidationProvider>
        </div>
        <!--Bio-->
        <div>
          <label class="register-label">Your bio</label>
          <ValidationProvider vid="bio" name="Bio" v-slot="{ errors }">
            <textarea rows="6" class="guestio-form-textarea resize-none" :class="{ 'border-red-500': errors.length }"
              v-model="guestForm.bio"></textarea>
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <!--Image uploader-->
        <div>
          <label class="register-label">Guest image</label>
          <ValidationProvider name="Image" vid="imageUrl" v-slot="{ errors }" class="flex">
            <div class="w-full" >
              <MediaUploadSingle entity="new-guest" :imageUrl="guestForm.imageUrl" @uploaded="uploadedImage"
                @deleted="deletedImage" rule="required" />
              <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>

        <!--Expertise-->
        <div>
          <label class="register-label">Your expertise</label>
          <TagSelect @selected="onTagSelected" />
        </div>

        <div>
          <label class="register-label">Categories</label>
          <ValidationProvider rules="required" name="Categories" vid="categories" v-slot="{ errors }">
            <CategorySelect @selected="onCategorySelected" />
            <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <!-- Links -->
        <Links :links="guestForm.links" />

        <GuestItems :items="guestForm.experience" empty="No experience added." add="Add experience">
          I have experience in...
        </GuestItems>

        <GuestItems :items="guestForm.topics" empty="No topics added." add="Add topic">
          Topics I like discussing...
        </GuestItems>

        <GuestItems :items="guestForm.questions" empty="No questions added." add="Add question">
          Questions to ask me...
        </GuestItems>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
import TextInput from "@/components/shared/TextInput"
import Links from "@/components/register/Links"
import GuestItems from "@/components/register/GuestItems"
import MediaUploadSingle from '@/components/shared/MediaUploadSingle';
import CategorySelect from '@/components/shared/CategorySelect';
import TagSelect from '@/components/shared/TagSelect';

export default {
  name: "GuestForm",
  components: { TextInput, Links, GuestItems, MediaUploadSingle, CategorySelect, TagSelect },
  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState('register', {
      guestForm: state => state.guestForm,
      tags: state => state.tags,
    })
  },

  methods: {
    onCategorySelected(categories) {
      if (categories) {
        let categoriesIds = categories.map(c => c.id);
        this.guestForm.categories = categoriesIds
      }
    },

    onTagSelected(tags) {
      if (tags) {
        this.guestForm.tags = tags
      }
    },

    uploadedImage(url) {
      this.$store.commit('register/setImageUrl', { guest: true, imageUrl: url })
    },

    deletedImage() {
      this.$store.commit('register/setImageUrl', { guest: true, imageUrl: null })
    }
  },

  mounted() {
    window.Event.$on('guestFormErrors', errors => {
      this.$refs.guestForm.setErrors(errors)
    })
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
